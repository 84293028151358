<template>
  <keep-alive>
    <v-container fluid class="d-flex flex-column h-100 pa-0 min-height-inherit">
      <div class="d-flex flex-row h-100 pa-0 min-height-inherit">
        <admin-left-section class="hidden-md-and-down" />
        <admin-business-section />
        <!-- <admin-right-section class="hidden-sm-and-down" /> -->
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import AdminBusinessSection from "../business/AdminBusinessSection";
import AdminLeftSection from "../home/AdminHomeSideMenu";
export default {
  components: {
    AdminLeftSection,
    AdminBusinessSection
  },
  data() {
    return {
      snackbar: false,
      errorMessage: null
    };
  },
  methods: {
    onSelectEvent(event) {
      console.log(event);
    }
  }
};
</script>
<style scoped>
.agency-title {
  color: #472583;
  font-size: 50px;
  font-weight: bold;
}
.title-line {
  height: 2px;
  width: 100%;
  background-color: #472583;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.product-title {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
.category-title {
  font-size: 32px;
  color: #472583;
  font-weight: 500;
}
.price-text {
  font-size: 18px;
  color: #472583;
  font-weight: 500;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
</style>
