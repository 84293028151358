<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Edit Business
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Enter the details for the new business.
      </v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column"
        style="font-family:'Poppins-Medium';color: #1e1e1e"
      >
        Select Logo
        <div class="d-flex justify-center">
          <editable-avatar
            :image="profile.image"
            :onImageSelected="onImageSelected"
          />
        </div>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column py-0">
        <v-text-field
          label="Name"
          solo
          flat
          outlined
          class="mt-2"
          v-model="profile.name"
          style="font-family:'Poppins-Regular';"
          :rules="[rules.required]"
          hide-details
        />
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column py-0">
        <v-text-field
          label="Website"
          solo
          flat
          outlined
          class="mt-2"
          v-model="profile.website"
          style="font-family:'Poppins-Regular';"
          :rules="[rules.required]"
        />
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column py-0">
        <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
          About
        </div>
        <v-textarea
          label="About"
          solo
          flat
          outlined
          class="mt-2"
          v-model="profile.about"
          style="font-family:'Poppins-Regular';"
          :rules="[rules.required]"
          rows="3"
        />
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
export default {
  components: { EditableAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    business: Object,
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      code: "",
      name: "",
      message: null,
      loading: false,
      sendingCode: false,
      disableResend: false,
      resendCount: 0,
      rules: {
        required: value => !!value || "Required."
      },
      selectedImage: null,
      profile: {}
    };
  },
  methods: {
    ...mapActions("business", {
      editBusiness: "editBusiness"
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      if (!this.profile.image && !this.selectedImage) {
        this.message = "Please select your profile image.";
        return;
      }
      if (!this.profile.name) {
        this.message = "Please add your name.";
        return;
      }
      if (!this.profile.website) {
        this.message = "Please add website.";
        return;
      }
      if (!this.profile.about) {
        this.message = "Please add about.";
        return;
      }
      this.message = null;
      var formData = new FormData();
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      formData.append("name", this.profile.name);
      formData.append("about", this.profile.about);
      formData.append("website", this.profile.website);
      formData.append("_id", this.profile._id);
      this.loading = true;
      this.editBusiness(formData)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch(error => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
      this.message = null;
    }
  },
  mounted() {
    this.profile = { ...this.business };
  }
};
</script>
<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
